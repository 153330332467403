import React, { useState } from "react";
import "./LandingPage.css";
const LandingPage = () => {
  const [poolCode, setPoolCode] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    window.location.href = `/${poolCode}`;
  };

  return (
    <div className="landing-page">
      <div className="content">
        <h1 className="app-title">Baby Guess</h1>
        {/* <p className="app-description">An exciting way to manage your tasks.</p> */}
        <p>Know the pool code? Search here!</p>
        <form onSubmit={handleSearch} className="search-form">
          <input
            type="text"
            value={poolCode}
            onChange={(e) => setPoolCode(e.target.value)}
            placeholder="Enter pool code"
            className="search-input"
          />
          <button type="submit" className="search-button">
            Search
          </button>
        </form>
        <div className="download-section">
          <a href="../../public/app-store-badge.png" className="store-link">
            <img
              src="/app-store-badge.png"
              alt="Apple Store"
              className="store-logo"
            />
          </a>
          <a href="../../public/google-play-badge.png" className="store-link">
            <img
              src="/google-play-badge.png"
              alt="Google Play"
              className="store-logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
