import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import PoolDetails from "./pages/PoolDetails";
import LandingPage from "./components/LandingPage";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/:id" element={<PoolDetails />} />
          <Route path="/" element={<LandingPage />} />
          {/* <Route
            path="/"
            element={
              <Grid container direction="column">
                <Grid item>
                  <Header />
                </Grid>
                <Grid item>
                  <Details />
                </Grid>
                <Grid item>
                  <Scoring />
                </Grid>
              </Grid>
            }
          /> */}
          {/* <Route
            path="/admin"
            element={
              <Grid item>
                <Header />
                <AdminPanel />
              </Grid>
            }
          /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
