import React, { useCallback, useEffect, useState } from "react";
import {
  CircularProgress,
  TextField,
  Button,
  Grid,
  Paper,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";

const GuessForm = ({ onGuessSubmit, poolDetails }) => {
  const initialGuessState = {
    email: "",
    name: "",
  };
  const [guess, setGuess] = useState(initialGuessState);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = useCallback(() => {
    if (!validateEmail(guess.email) || !guess.name) {
      return false;
    }

    const isValid = poolDetails.scoring.every((item) => {
      if (item.type === "Weight (lb)") {
        const hasPounds = Boolean(guess[item.category]?.value?.pounds);
        const hasOunces =
          guess[item.category]?.value?.ounces !== undefined &&
          guess[item.category]?.value?.ounces !== null;

        return hasPounds && hasOunces;
      }
      return guess[item.category]?.value;
    });
    return isValid;
  }, [guess, poolDetails.scoring]);

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [guess, validateForm]);

  const handleChange = (value, category, type) => {
    let newValue = { value, type };

    if (category === "name") {
      setGuess({ ...guess, name: value });
      return;
    } else if (category === "email") {
      setGuess({ ...guess, email: value });
      return;
    } else if (type === "pounds" || type === "ounces") {
      let ouncesValue =
        type === "ounces"
          ? parseInt(value, 10)
          : guess[category]?.value?.ounces;
      let poundsValue =
        type === "pounds"
          ? parseInt(value, 10)
          : guess[category]?.value?.pounds;

      newValue = {
        ...guess[category],
        value: { pounds: poundsValue, ounces: ouncesValue },
        type: "Weight (in lb)",
      };
    } else if (type !== "Date" && type !== "Time") {
      newValue = { value, type };
    }

    if (type === "Date") {
      const formattedDate = moment(value).format("YYYY-MM-DD");
      console.log(formattedDate);
      newValue = { value: formattedDate, type };
      setGuess({ ...guess, [category]: newValue });
      return;
    } else if (type === "Time") {
      const formattedTime = moment(value).format("HH:mm:ss");
      newValue = { value: formattedTime, type };
      setGuess({ ...guess, [category]: newValue });
    }

    setGuess({ ...guess, [category]: newValue });
    setIsFormValid(validateForm());
  };
  console.log(guess);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formattedGuesses = Object.keys(guess).reduce((acc, key) => {
      if (key !== "email" && key !== "name") {
        acc.push({
          category: key,
          value: guess[key].value,
          valueType: guess[key].type,
        });
      }
      return acc;
    }, []);

    const guessData = {
      name: guess.name,
      email: guess.email,
      guesses: formattedGuesses,
    };
    onGuessSubmit(guessData)
      .then((data) => {
        if (data) {
          setIsSubmitting(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const renderInputField = (item) => {
    switch (item.type) {
      case "Word":
      case "Sex":
        return (
          <FormControl fullWidth>
            <InputLabel
              id={`label-${item.category}`}
            >{`Guess for ${item.category} (${item.type})`}</InputLabel>
            <Select
              labelId={`label-${item.category}`}
              value={guess[item.category]?.value || ""}
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(e) =>
                handleChange(e.target.value, item.category, item.type)
              }
            >
              <MenuItem value="M">M</MenuItem>
              <MenuItem value="F">F</MenuItem>
            </Select>
          </FormControl>
        );
      case "Number":
        return (
          <TextField
            label={`Guess for ${item.category} (${item.type})`}
            name={item.category}
            fullWidth
            onChange={(e) =>
              handleChange(e.target.value, item.category, item.type)
            }
            required
            type={item.type === "Number" ? "number" : "text"}
          />
        );
      case "Weight (lb)":
        const minPounds = 5;
        const maxPounds = 10;
        const ouncesPerPound = 16;

        const poundOptions = [];
        for (let lbs = minPounds; lbs <= maxPounds; lbs++) {
          poundOptions.push(lbs);
        }

        const ounceOptions = [];
        for (let oz = 0; oz < ouncesPerPound; oz++) {
          ounceOptions.push(oz);
        }

        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id={`label-pounds-${item.category}`}
                >{`Pounds for ${item.category}`}</InputLabel>
                <Select
                  labelId={`label-pounds-${item.category}`}
                  value={guess[item.category]?.value?.pounds || ""}
                  label={`Pounds for ${item.category}`}
                  onChange={(e) =>
                    handleChange(e.target.value, item.category, "pounds")
                  }
                >
                  {poundOptions.map((pounds, index) => (
                    <MenuItem key={index} value={pounds}>
                      {`${pounds} lbs`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id={`label-ounces-${item.category}`}
                >{`Ounces for ${item.category}`}</InputLabel>
                <Select
                  labelId={`label-ounces-${item.category}`}
                  value={guess[item.category]?.value?.ounces?.toString() || ""}
                  label={`Ounces for ${item.category}`}
                  onChange={(e) =>
                    handleChange(e.target.value, item.category, "ounces")
                  }
                >
                  {ounceOptions.map((ounces, index) => (
                    <MenuItem key={index} value={ounces}>
                      {`${ounces} oz`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
      case "Weight (kg)":
        const minWeight = 2.5;
        const maxWeight = 4.5;
        const weightStep = 0.1;
        const weightOptions = [];
        for (
          let weight = minWeight;
          weight <= maxWeight;
          weight += weightStep
        ) {
          weightOptions.push(weight.toFixed(1));
        }

        return (
          <FormControl fullWidth>
            <InputLabel
              id={`label-${item.category}`}
            >{`Guess for ${item.category} (${item.type})`}</InputLabel>
            <Select
              labelId={`label-${item.category}`}
              value={guess[item.category]?.value || ""}
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(e) =>
                handleChange(e.target.value, item.category, item.type)
              }
            >
              {weightOptions.map((weight, index) => (
                <MenuItem key={index} value={weight}>
                  {`${weight} kg`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(date) => handleChange(date, item.category, item.type)}
              renderInput={(params) => (
                <TextField {...params} fullWidth required />
              )}
              minDate={new Date()}
            />
          </LocalizationProvider>
        );
      case "Time":
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(time) => handleChange(time, item.category, item.type)}
              renderInput={(params) => (
                <TextField {...params} fullWidth required />
              )}
              thresholdToRenderTimeInASingleColumn={24}
              timeSteps={{ hours: 1, minutes: 5, seconds: 5 }}
              skipDisabled={true}
            />
          </LocalizationProvider>
        );
      case "Letter":
        return (
          <FormControl fullWidth>
            <InputLabel
              id={`label-${item.category}`}
            >{`Guess for ${item.category} (${item.type})`}</InputLabel>
            <Select
              labelId={`label-${item.category}`}
              value={guess[item.category]?.value || ""}
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(e) =>
                handleChange(e.target.value, item.category, item.type)
              }
            >
              {[...Array(26)].map((_, i) => (
                <MenuItem key={i} value={String.fromCharCode(65 + i)}>
                  {String.fromCharCode(65 + i)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "Length (in)":
        const inchOptions = Array.from({ length: 36 }, (_, i) => i + 1);
        return (
          <FormControl fullWidth>
            <InputLabel
              id={`label-${item.category}`}
            >{`Guess for ${item.category} (${item.type})`}</InputLabel>
            <Select
              labelId={`label-${item.category}`}
              value={guess[item.category]?.value || ""}
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(e) =>
                handleChange(e.target.value, item.category, item.type)
              }
            >
              {inchOptions.map((inch, index) => (
                <MenuItem key={index} value={inch}>{`${inch} in`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case "Length (cm)":
        const cmOptions = Array.from({ length: 91 }, (_, i) => i + 1);
        return (
          <FormControl fullWidth>
            <InputLabel
              id={`label-${item.category}`}
            >{`Guess for ${item.category} (${item.type})`}</InputLabel>
            <Select
              labelId={`label-${item.category}`}
              value={guess[item.category]?.value || ""}
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(e) =>
                handleChange(e.target.value, item.category, item.type)
              }
            >
              {cmOptions.map((cm, index) => (
                <MenuItem key={index} value={cm}>{`${cm} cm`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "Name Length":
        const nameLengthOptions = Array.from({ length: 20 }, (_, i) => i + 1);
        return (
          <FormControl fullWidth>
            <InputLabel
              id={`label-${item.category}`}
            >{`Guess for ${item.category} (${item.type})`}</InputLabel>
            <Select
              labelId={`label-${item.category}`}
              value={guess[item.category]?.value || ""}
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(e) =>
                handleChange(e.target.value, item.category, item.type)
              }
            >
              {nameLengthOptions.map((length, index) => (
                <MenuItem key={index} value={length}>{`${length}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case "Hair Color":
        const hairColors = [
          "Black",
          "Brown",
          "Blonde",
          "Red",
          "Auburn",
          "Grey",
          "White",
        ];
        return (
          <FormControl fullWidth>
            <InputLabel
              id={`label-${item.category}`}
            >{`Guess for ${item.category} (${item.type})`}</InputLabel>
            <Select
              labelId={`label-${item.category}`}
              value={guess[item.category]?.value || ""}
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(e) =>
                handleChange(e.target.value, item.category, item.type)
              }
            >
              {hairColors.map((color, index) => (
                <MenuItem key={index} value={color}>
                  {color}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case "Eye Color":
        const eyeColors = ["Brown", "Blue", "Green", "Hazel", "Grey", "Amber"];
        return (
          <FormControl fullWidth>
            <InputLabel
              id={`label-${item.category}`}
            >{`Guess for ${item.category} (${item.type})`}</InputLabel>
            <Select
              labelId={`label-${item.category}`}
              value={guess[item.category]?.value || ""}
              label={`Guess for ${item.category} (${item.type})`}
              onChange={(e) =>
                handleChange(e.target.value, item.category, item.type)
              }
            >
              {eyeColors.map((color, index) => (
                <MenuItem key={index} value={color}>
                  {color}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };

  return (
    <Paper sx={{ p: 3, m: 2 }}>
      {isSubmitting ? (
        <CircularProgress
          size={24}
          sx={{
            position: "relative",
            left: "50%",
            marginLeft: "-12px",
            marginTop: "12px",
          }}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                fullWidth
                value={guess.name}
                onChange={(e) => handleChange(e.target.value, "name", "text")}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                value={guess.email}
                onChange={(e) => handleChange(e.target.value, "email", "text")}
                required
              />
            </Grid>

            {poolDetails.scoring.map((item, index) => (
              <Grid item xs={12} key={index}>
                {renderInputField(item)}
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isFormValid || isSubmitting}
              >
                Submit Guess
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Paper>
  );
};

export default GuessForm;
