import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

const Header = () => (
  <AppBar position="static" sx={{ boxShadow: "none", bgcolor: "#546E7A" }}>
    {" "}
    {/* Updated color */}
    <Toolbar>
      <Typography
        variant="h5"
        sx={{ flexGrow: 1, textAlign: "center", color: "white" }}
      >
        Baby Guess
      </Typography>
    </Toolbar>
  </AppBar>
);

export default Header;
